<template>
  <section class="w-75 m-auto account-main-div">
    <p class="text-h6 text-center">{{ $t("kyc_verification_text") }}</p>
    <p class="grey--text">
      {{ $t("kyc_verification_sub_text") }}
    </p>
    <p class="text-center p-0 m-0">{{ $t("kyc_live_sub") }}:</p>
    <p class="text-center grey--text p-0 m-0">
      {{ $t("kyc_live_sub_text") }}
    </p>
    <p class="text-center p-0 m-0">{{ $t("kyc_face_sub") }}:</p>
    <p class="text-center grey--text p-0 m-0">
      {{ $t("kyc_face_sub_text") }}
    </p>
    <div class="d-flex justify-content-center">
      <v-card
        elevation="4"
        rounded="xl"
        tile
        :class="
          isActive
            ? 'border-primary accoun account-selection'
            : 'border-light account-selection'
        "
        class="card-selector text-center rounded-xl m-4"
        @click="onClickKyc('personal')"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isActive
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("kyc_face_sub") }}
          </h6>
          <br />
          <v-icon
            :color="isActive ? 'primary' : 'grey'"
            large
            class="account-icons"
            :style="
              isActive
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
          >
            mdi-shield-check
          </v-icon>
          <br />

          <v-icon
            v-show="isActive"
            color="primary"
            large
            class="float-right account-icons-check"
            style="position: absolute; bottom: 15px; right: 20px"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>

      <v-card
        rounded="xl"
        elevation="4"
        tile
        :class="
          isActiveBusiness
            ? 'border-primary accoun account-selection'
            : 'border-light account-selection'
        "
        class="card-selector text-center rounded-xl m-4"
        @click="onClickEkyc('business')"
      >
        <div class="p-4 h-100" style="position: relative">
          <h6
            :class="
              isActiveBusiness
                ? 'text-primary-color font-weight-bold'
                : 'text-secondary font-weight-normal'
            "
          >
            {{ $t("kyc_live_sub") }}
          </h6>
          <br />
          <v-icon
            :color="isActiveBusiness ? 'primary' : 'grey'"
            large
            class="account-icons"
            style="
              width: 90px;
              height: 85px;
              color: #b4b4b4;
              border-radius: 50%;
            "
            :style="
              isActiveBusiness
                ? 'border: 1px solid #ff6b00;'
                : 'border: 1px solid #6c757d;'
            "
          >
            mdi-shield-account
          </v-icon>
          <br />

          <v-icon
            v-show="isActiveBusiness"
            color="primary"
            large
            class="float-right account-icons-check"
            style="position: absolute; bottom: 15px; right: 20px"
          >
            mdi-check-circle
          </v-icon>
        </div>
      </v-card>
    </div>
    <v-btn
      class="btn-primary-outlined text-capitalize float-right mt-5 me-3 px-5"
      outlined
      @click="onClickNext"
    >
      {{ $t("next") }}
      <v-icon dark right> mdi-arrow-right </v-icon>
    </v-btn>
    <v-dialog v-model="isdownloadapp" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          {{ $t("mobile_info") }}
        </v-card-title>

        <v-card-text class="pt-2 text-h6 text-left">
          {{ $t("download_mobile_app_text") }}
        </v-card-text>
        <v-divider class="mb-0"></v-divider>
        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="isdownloadapp = false">
            {{ $t("okay") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ekycDialog" width="500">
      <v-card>
        <v-card-title class="pa-1 ps-2">
          <span class="primary--text font-weight-bold"
            >{{ $t("kyc_live_sub") }}:</span
          >
          <v-spacer></v-spacer>
          <v-btn color="" fab icon small @click="ekycDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="m-0 p-0"></v-divider>
        <v-card-text class="pt-2 text-left">
          {{ $t("live_sub_txn_limit") }} :<br />
          <b> {{ $t("malaysian_expat") }}:</b>
          <p class="black--text font-weight-bold">
            {{ $t("txn_limit_text") }}:
            <span class="primary--text font-weight-bold">RM 30,000 /day</span>
          </p>
          <b> 2. {{ $t("migrant_foreign_work") }} </b>
          <p class="black--text font-weight-bold">
            {{ $t("txn_limit_text") }}:
            <span class="primary--text font-weight-bold">RM 5,000/month</span>
          </p>
          * {{ $t("Refers_to_Blue_collaredmigrant") }} <br />
          * {{ $t("sender_and_reciever_cntry") }}
        </v-card-text>
        <v-divider class="mb-0"></v-divider>
      </v-card>
    </v-dialog>
    <v-dialog v-model="kycDialog" width="500">
      <v-card>
        <v-card-title class="pa-1 ps-2">
          <span class="primary--text font-weight-bold">
            {{ $t("kyc_face_sub") }}:
          </span>
          <v-spacer></v-spacer>
          <v-btn color="" fab icon small @click="kycDialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="m-0 p-0"></v-divider>
        <v-card-text class="pt-2 text-left">
          {{ $t("choose_live_sub") }}:
          <p class="black--text font-weight-bold">
            {{ $t("txn_limit_text") }}:
            <span class="primary--text font-weight-bold">RM 50,000/day</span>
          </p>
          <p>{{ $t("onsite_f2f_verification_subtext") }}:</p>
          <ol>
            <li>{{ $t("my_kad") }}</li>
            <li>{{ $t("passport_foreign") }}</li>
          </ol>
          <p>{{ $t("acceptable_id_list") }}:</p>
          <ol>
            <li>{{ $t("myKad") }}</li>
            <li>{{ $t("myPr") }}</li>
            <li>{{ $t("driving_license") }}</li>
          </ol>
          <p>
            <a class="primary--text">{{ $t("please_clickhere") }}</a
            >, {{ $t("our_customer_support") }}
          </p>
        </v-card-text>
        <v-divider class="mb-0"></v-divider>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
export default {
  name: "BenificiaryType",
  data() {
    return {
      name: "",
      kycDialog: false,
      ekycDialog: false,
      isActive: false,
      hasError: false,
      isActiveBusiness: false,
      hasErrorBusiness: false,
      isActivepersonal: false,
      isdownloadapp: false,
      isactiveBus: false,
      Evolet: true,
    };
  },
  props: {
    isTrans: {
      type: String,
      default: "",
    },
    openKycDetials: {
      type: Boolean,
    },
  },
  watch: {
    openKycDetials: {
      handler(val) {
        if (!val) {
          this.iskycDetails = val;
        } else {
          this.iskycDetails = val;
        }
      },
    },
    isActiveBusiness(val) {
      if (val) {
        this.ekycDialog = true;
      }
    },
    isActive(val) {
      if (val) {
        this.kycDialog = true;
      }
    },
  },
  methods: {
    ekyc() {
      const token = sessionStorage.getItem("access_token");
      try {
        window.QQPayMobile.initEKYC(token);
      } catch (err) {
        try {
          window.webkit.messageHandlers.initEKYC.postMessage(token);
        } catch (err) {
          if (err) {
            this.isdownloadapp = true;
          }
          console.log(err);
        }
      }
    },
    onClickCancel() {
      this.$router.push("/transaction/3");
    },
    async onClickNext() {
      if (this.isActive) {
        this.$emit("onClickNext");
        this.kycDialog = false;
      }
      if (this.isActiveBusiness) {
        this.ekyc();
        this.ekycDialog = false;
      }
    },
    onClickKyc() {
      this.isActive = true;
      this.isActiveBusiness = false;
    },
    onClickEkyc() {
      this.isActive = false;
      this.isActiveBusiness = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.btn-light {
  font-weight: bold;
  color: $dimgrey;
  background: $white;
  margin: 0 10px;
  &:not(:disabled) {
    &:not(.disabled).active {
      color: $secondary;
    }
    &:not(.disabled) {
      &:active {
        color: $secondary;
      }
    }
  }
  &:hover {
    background: $white;
  }
}
.selected_class {
  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    background: #f8f9fa;
    border-radius: 7px;
  }
}
button.btn.px-5.py-3.btn-light.btn-lg.individual-select {
  border: 1px solid rgb(255, 0, 43) !important;
  background: #fff;
}
button.btn.px-5.py-3.btn-light.btn-lg.business-select {
  border: 1px solid red !important;
  background: #fff;
}
.border-primary {
  border: 1px solid $primary;
}
</style>
